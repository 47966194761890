'use client'

import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import Link from 'next/link'
import { Map } from 'react-kakao-maps-sdk'

import Image from 'next/image'

export default function Footer() {
  return (
    <>
      <Box
        backgroundColor={'white'}
        display={{ base: 'none', md: 'block' }} // 데스크톱에서만 표시
      >
        <Flex
          h="272px"
          maxWidth="1440px"
          margin="0 auto"
          justifyContent={'space-around'}>
          <Box py={16}>
            <Image
              loading="lazy"
              width={200}
              height={60}
              unoptimized
              src="https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/33f3c1cb-88d1-4eb8-0315-a8e2f89af500/avatar?auto=format"
              alt="여름숲_로고"
            />
            <Flex
              gap={6}
              justifyContent={'space-between'}
              mt={4}>
              <Link
                target="_blank" // 새 탭에서 열리도록 설정
                href="https://yeoreumsoop.com">
                <Image
                  loading="lazy"
                  src="/naver_black.svg"
                  alt="여름숲 네이버"
                  width={24}
                  height={24}
                />
              </Link>
              <Link
                target="_blank" // 새 탭에서 열리도록 설정
                href="https://pf.kakao.com/_fwrLG">
                <Image
                  loading="lazy"
                  src="/kakao_black.svg"
                  alt="여름숲 카카오"
                  width={24}
                  height={24}
                />
              </Link>
              <Link href="https://www.instagram.com/yeoreum_soop/">
                <Image
                  loading="lazy"
                  src="/instagram_black.svg"
                  alt="여름숲 인스타그램"
                  width={24}
                  height={24}
                />
              </Link>
              <Link href="https://www.youtube.com/@yeoreumsoop">
                <Image
                  loading="lazy"
                  src="/youtube_black.svg"
                  alt="여름숲 유튜브"
                  width={24}
                  height={24}
                />
              </Link>
            </Flex>
          </Box>

          <Box py={16}>
            <Text
              fontWeight={600}
              fontSize={16}>
              ABOUT
            </Text>
            <Text
              maxW={'262px'}
              fontSize={12}
              pt={4}>
              여름숲 : 식물하는 부부의 공간
              <br />
              <br />
              초록을 만나고, 소개하고, 디자인하고, 판매합니다.
              <br />
              여행하듯 열정적이고 자유롭게. 다양한 경험을 가진 우리는
              <br />
              <br /> - 무엇보다 우리다운- 설레는 일들을 합니다.
            </Text>
          </Box>
          <Box py={16}>
            <VStack
              gap={4}
              justifyContent={'start'}
              textAlign={'start'}
              alignItems={'start'}>
              <Text
                fontWeight={600}
                fontSize={16}>
                CONTACT
              </Text>

              <Text fontSize={12}>
                신한은행 : 110-490-778670 천하림(여름숲)
              </Text>
              <Text
                fontSize={12}
                _hover={{ textDecoration: 'underline' }} // 호버 시 밑줄 추가
                onClick={() => (window.location.href = 'tel:0507-1380-1181')} // 전화번호 클릭 시 전화 걸기
              >
                연락처 : 0507-1380-1181
              </Text>

              <Text
                fontSize={12}
                onClick={() =>
                  (window.location.href = 'mailto:yeoreumsoop@yeoreumsoop.com')
                }
                cursor="pointer" // 클릭 가능하다는 것을 나타내기 위한 스타일 추가
              >
                제휴문의 : yeoreumsoop@yeoreumsoop.com
              </Text>
              <Text
                fontSize={12}
                as={Link}
                _hover={{ textDecoration: 'underline' }} // 호버 시 밑줄 추가
                href={`https://naver.me/FN7Bco8b`}>
                주소 : 제주 제주시 애월읍 수산4길 61, 파란창고
              </Text>
            </VStack>
          </Box>
          <Box py={16}>
            <Map
              center={{ lat: 33.468873, lng: 126.390508 }}
              style={{ width: '262px', height: '160px' }}
              level={2}
            />
          </Box>
        </Flex>
      </Box>
      <HStack
        backgroundColor={'primary'}
        height={'32px'}
        display={{ base: 'none', md: 'flex' }} // 데스크톱에서만 표시
      >
        <Flex
          py={'2'}
          maxWidth="1440px"
          width="100%"
          px={32}>
          {' '}
          <Flex
            justifyContent="space-between"
            width="100%">
            {' '}
            {/* 전체 너비 차지 */}
            <Text
              fontSize={12}
              color={'white'}>
              © 2024 Yeoreum_soop
            </Text>
            <Box></Box> {/* 오른쪽 정렬됨 */}
          </Flex>
        </Flex>
      </HStack>

      <>
        <Box
          backgroundColor={'white'}
          w={'100%'}
          display={{ base: 'block', md: 'none' }} // 모바일에서만 표시
        >
          <VStack
            margin="0 auto"
            textAlign={'start'}>
            <Box py={8}>
              <Image
                loading="lazy"
                width={200}
                height={60}
                unoptimized
                src="https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/33f3c1cb-88d1-4eb8-0315-a8e2f89af500/avatar?auto=format"
                alt="여름숲_로고"
              />
              <Flex
                gap={6}
                justifyContent={'space-between'}
                mt={4}>
                <Link
                  target="_blank" // 새 탭에서 열리도록 설정
                  href="https://yeoreumsoop.com">
                  <Image
                    loading="lazy"
                    src="/naver_black.svg"
                    alt="여름숲 네이버"
                    width={24}
                    height={24}
                  />
                </Link>
                <Link
                  target="_blank" // 새 탭에서 열리도록 설정
                  href="https://pf.kakao.com/_fwrLG">
                  <Image
                    loading="lazy"
                    src="/kakao_black.svg"
                    alt="여름숲 카카오"
                    width={24}
                    height={24}
                  />
                </Link>
                <Link href="https://www.instagram.com/yeoreum_soop/">
                  <Image
                    loading="lazy"
                    src="/instagram_black.svg"
                    alt="여름숲 인스타그램"
                    width={24}
                    height={24}
                  />
                </Link>
                <Link href="https://www.youtube.com/@yeoreumsoop">
                  <Image
                    loading="lazy"
                    src="/youtube_black.svg"
                    alt="여름숲 유튜브"
                    width={24}
                    height={24}
                  />
                </Link>
              </Flex>
            </Box>

            <VStack
              margin="0 auto"
              textAlign={'start'}
              justifyContent={'start'}
              align="stretch" // 모든 자식 요소가 가로로 확장
            >
              <Box
                py={4}
                width="100%">
                {' '}
                {/* width="100%"를 추가하여 왼쪽 정렬 */}
                <Text
                  fontWeight={600}
                  fontSize={16}>
                  ABOUT
                </Text>
                <Text
                  fontSize={12}
                  pt={4}>
                  식물하는 부부의 공간
                </Text>
              </Box>

              <Box
                py={4}
                width="100%">
                <VStack
                  gap={4}
                  justifyContent={'start'}
                  textAlign={'start'}
                  alignItems={'start'}>
                  <Text
                    fontWeight={600}
                    fontSize={16}>
                    CONTACT
                  </Text>

                  <Text fontSize={12}>
                    신한은행 : 110-490-778670 천하림(여름숲)
                  </Text>
                  <Text
                    fontSize={12}
                    _hover={{ textDecoration: 'underline' }} // 호버 시 밑줄 추가
                    onClick={() =>
                      (window.location.href = 'tel:0507-1380-1181')
                    } // 전화번호 클릭 시 전화 걸기
                  >
                    연락처 : 0507-1380-1181
                  </Text>

                  <Text
                    fontSize={12}
                    onClick={() =>
                      (window.location.href =
                        'mailto:yeoreumsoop@yeoreumsoop.com')
                    }
                    cursor="pointer" // 클릭 가능하다는 것을 나타내기 위한 스타일 추가
                  >
                    제휴문의 : yeoreumsoop@yeoreumsoop.com
                  </Text>
                  <Text
                    fontSize={12}
                    as={Link}
                    _hover={{ textDecoration: 'underline' }} // 호버 시 밑줄 추가
                    href={`https://naver.me/FN7Bco8b`}>
                    주소 : 제주 제주시 애월읍 수산4길 61, 파란창고
                  </Text>
                </VStack>
              </Box>

              <Box
                pb={6}
                width="100%">
                <Map
                  center={{ lat: 33.468873, lng: 126.390508 }}
                  style={{ width: '262px', height: '243px' }}
                  level={2}
                />
              </Box>
            </VStack>
          </VStack>
        </Box>
        <HStack
          backgroundColor={'primary'}
          height={'32px'}
          display={{ base: 'flex', md: 'none' }} // 모바일에서만 표시
        >
          <Flex
            py={'2'}
            width="100%"
            px={16}>
            {' '}
            <Flex
              justifyContent="space-between"
              width="100%">
              {' '}
              {/* 전체 너비 차지 */}
              <Text
                fontSize={12}
                color={'white'}>
                © 2024 Yeoreum_soop
              </Text>
              <Box></Box> {/* 오른쪽 정렬됨 */}
            </Flex>
          </Flex>
        </HStack>
      </>
    </>
  )
}
