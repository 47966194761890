'use client'

import {
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { usePathname } from 'next/navigation'
import { Link } from '@chakra-ui/next-js'
import { useEffect, useState } from 'react'
import { authService } from '@/FBase'
import { useQuery } from '@tanstack/react-query'
import { getUser } from '@/api'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoCloseSharp } from 'react-icons/io5'

import Image from 'next/image'

interface IGoogleUser {
  name: string
  avatar: string
  email: string
  displayName: string
  photoURL: string
  uid: string
}

export default function Navigation() {
  const [init, setInit] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState<IGoogleUser | null>(null)
  const { isOpen, onToggle } = useDisclosure()
  const path = usePathname()

  const [isLoading, setIsLoading] = useState(false)
  // 페이지가 변경될 때마다 로딩 상태를 초기화
  useEffect(() => {
    setIsLoading(false)
  }, [path])

  const handleCardClick = () => {
    setIsLoading(true)
  }

  // 🗺 멤버 정보 가져오기
  const { isLoading: userLoading, data: userInfo } = useQuery({
    queryKey: ['userInfo', user?.uid],
    queryFn: getUser,
    enabled: !!user?.uid, // user.uid가 존재할 때만 쿼리 실행
  })

  // 유저를 가지고 있는지 확인하는 역할
  useEffect(() => {
    authService.onAuthStateChanged((data: any) => {
      if (data) {
        // 이메일을 가지고 있다면 로그인
        if (data.email !== null) {
          setIsLoggedIn(true)
        } else {
          setIsLoggedIn(false)
        }
        setUser(data)
        setInit(true)
      } else {
        setIsLoggedIn(false)
        setInit(true)
      }
    })
  }, [])

  // 로그아웃 함수
  const onLogOutClick = () => {
    authService.signOut()
    // 로그아웃 후 홈으로 이동
    window.location.href = '/'
  }

  return (
    <Box>
      {/* PRIMARY COLOR PART */}

      <Box
        backgroundColor={'primary'}
        zIndex={99}
        display={{ base: 'none', md: 'block' }} // 데스크톱에서만 표시
      >
        <HStack
          justifyContent={'space-between'}
          height={'32px'}
          py={'2'}
          mx={'auto'}
          px={32}
          maxW={'1440px'}>
          <Flex gap={6}>
            <Link
              target="_blank" // 새 탭에서 열리도록 설정
              href="https://yeoreumsoop.com">
              <Image
                src="/naver.svg"
                alt="여름숲"
                width={24}
                height={24}
                loading="lazy"
              />
            </Link>
            <Link
              target="_blank" // 새 탭에서 열리도록 설정
              href="https://pf.kakao.com/_fwrLG">
              <Image
                src="/kakao.svg"
                alt="여름숲"
                width={24}
                height={24}
                loading="lazy"
              />
            </Link>
            <Link
              target="_blank" // 새 탭에서 열리도록 설정
              href="https://www.instagram.com/yeoreum_soop/">
              <Image
                src="/instagram.svg"
                alt="여름숲"
                width={24}
                height={24}
                loading="lazy"
              />
            </Link>
            <Link
              target="_blank" // 새 탭에서 열리도록 설정
              href="https://www.youtube.com/@yeoreumsoop">
              <Image
                src="/youtube.svg"
                alt="여름숲"
                width={24}
                height={24}
                loading="lazy"
              />
            </Link>
          </Flex>

          {/* HELP CENTER */}
          <Flex>
            <IconButton
              variant="link"
              aria-label="고객센터"
              icon={
                <TfiHeadphoneAlt
                  color="white"
                  size="24px"
                />
              }
            />
            <Text
              _hover={{ cursor: 'pointer' }}
              color="white"
              fontWeight={'bold'}
              onClick={() => (window.location.href = 'tel:0507-1380-1181')} // 전화번호 클릭 시 전화 걸기
            >
              0507-1380-1181
            </Text>
          </Flex>
        </HStack>
      </Box>
      {/* NAVIGATION PART */}
      <Box
        backgroundColor={'white'}
        display={{ base: 'none', md: 'block' }} // 데스크톱에서만 표시
      >
        <HStack
          height={'88px'}
          mx={'auto'}
          px={16}
          justifyContent={'start'}
          maxW={'1440px'}>
          {/* LOGO */}
          <Link
            href="/"
            onClick={handleCardClick}
            cursor={isLoading ? 'wait' : 'pointer'}>
            <Image
              width={200}
              height={60}
              unoptimized
              src="https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/33f3c1cb-88d1-4eb8-0315-a8e2f89af500/avatar?auto=format"
              alt="여름숲_logo"
              loading="lazy"
              quality={10}
            />
          </Link>
          {/* ABOUT, megazinE */}
          <Flex
            gap={14}
            pl={8}>
            {/* 메뉴1 */}
            <Link
              href="/abouts"
              _hover={{ textDecoration: 'none' }}>
              <Text
                _hover={{ color: 'primary' }}
                fontSize={'16px'}
                fontWeight={'700'}
                onClick={handleCardClick}
                cursor={isLoading ? 'wait' : 'pointer'}
                color={path === '/abouts' ? 'primary' : 'header-black'}>
                ABOUT
              </Text>
            </Link>

            {/* 메뉴2 */}
            <Link
              href="/megazines"
              _hover={{ textDecoration: 'none' }}>
              <Text
                _hover={{ color: 'primary' }}
                fontSize={'16px'}
                fontWeight={'700'}
                onClick={handleCardClick}
                cursor={isLoading ? 'wait' : 'pointer'}
                color={
                  path.startsWith('/megazines') ? 'primary' : 'header-black'
                }>
                MEGAZINE
              </Text>
            </Link>

            {/* 메뉴3 */}
            <Link
              href="/shops"
              _hover={{ textDecoration: 'none' }}>
              <Text
                _hover={{ color: 'primary' }}
                fontSize={'16px'}
                fontWeight={'700'}
                onClick={handleCardClick}
                cursor={isLoading ? 'wait' : 'pointer'}
                color={path.startsWith('/shops') ? 'primary' : 'header-black'}>
                SHOP
              </Text>
            </Link>
          </Flex>

          {/* LOGIN 여기선 안 씀 ❌ */}
          <Flex
            gap={2}
            pr={16}
            ml="auto">
            {!isLoggedIn ? (
              <>
                {/* <Button variant={'outline'}>
                      <Link href="/login">로그인</Link>
                    </Button> */}
              </>
            ) : (
              <Menu autoSelect={false}>
                <MenuButton>
                  <Avatar
                    size={'md'}
                    name={user?.email}
                    src={user?.photoURL}
                  />
                </MenuButton>

                <MenuList
                  sx={{ minWidth: '180px' }}
                  zIndex={99}>
                  {userInfo?.level === 1 ? (
                    <Flex
                      direction="column"
                      align="center"
                      width="100%">
                      <Link
                        href="/adminPage"
                        style={{ width: '100%' }}
                        _hover={{ textDecoration: 'none' }}>
                        <MenuItem
                          width={'100%'}
                          textAlign="center"
                          justifyContent="center">
                          관리자 페이지
                        </MenuItem>
                        <MenuDivider />
                      </Link>
                    </Flex>
                  ) : null}

                  <MenuItem
                    width={'100%'}
                    textAlign="center"
                    justifyContent="center"
                    onClick={onLogOutClick}>
                    Log out
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </HStack>
      </Box>
      {/* 모바일용 화면 */}
      <Box
        backgroundColor={'white'}
        h={'48px'}
        px={4}
        py={2}
        display={{ base: 'block', md: 'none' }} // 모바일에서만 표시
      >
        <Flex>
          <Link href="/">
            <Image
              width={100}
              height={60}
              unoptimized
              src="https://imagedelivery.net/6ZZEpKdXI04JJCvPE__Mog/33f3c1cb-88d1-4eb8-0315-a8e2f89af500/avatar?auto=format"
              alt="여름숲_logo"
              loading="lazy"
              quality={10}
            />
          </Link>
          <IconButton
            size={'32px'}
            icon={<GiHamburgerMenu />}
            backgroundColor={'white'}
            _hover={{ backgroundColor: 'white' }}
            onClick={onToggle}
            aria-label="Menu"
            ml="auto" // 오른쪽 마진 추가
          />
        </Flex>
      </Box>

      {/* 햄버거 메뉴 */}
      {isOpen && (
        <>
          <Box
            display={{ base: 'block', md: 'none' }} // 모바일에서만 표시
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            bg="rgba(0, 0, 0, 0.5)" // 어두운 배경
            onClick={onToggle} // Overlay 클릭 시 메뉴 닫기
            zIndex="overlay" // zIndex를 적절하게 설정하여 메뉴 위에 표시되도록 함
          />
          <Box
            position="fixed"
            top="0"
            right="0"
            width="250px"
            height="100vh"
            bg="white"
            zIndex="popover"
            // boxShadow="lg"
          >
            <Flex justifyContent={'flex-end'}>
              <IconButton
                icon={<IoCloseSharp />}
                size={'lg'}
                backgroundColor={'white'}
                onClick={onToggle}
                aria-label="Menu"
                ml="auto" // 오른쪽 마진 추가
              />
            </Flex>
            <Flex
              gap={8}
              onClick={onToggle}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}>
              <Link
                href="/abouts"
                _hover={{ textDecoration: 'none' }}>
                <Text
                  _hover={{ color: 'primary' }}
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={path === '/abouts' ? 'primary' : 'header-black'}>
                  ABOUT
                </Text>
              </Link>
              <Divider />
              <Link
                href="/megazines"
                _hover={{ textDecoration: 'none' }}>
                <Text
                  _hover={{ color: 'primary' }}
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={
                    path.startsWith('/megazines') ? 'primary' : 'header-black'
                  }>
                  MEGAZINE
                </Text>
              </Link>
              <Divider />
              <Link
                href="/shops"
                _hover={{ textDecoration: 'none' }}>
                <Text
                  _hover={{ color: 'primary' }}
                  fontSize={'16px'}
                  fontWeight={'700'}
                  color={
                    path.startsWith('/shops') ? 'primary' : 'header-black'
                  }>
                  SHOP
                </Text>
              </Link>
              <Divider />

              <Flex gap={2}>
                {!isLoggedIn ? (
                  <>
                    {/* <Button variant={'outline'}>
                          <Link href="/login">로그인</Link>
                        </Button> */}
                  </>
                ) : (
                  <Menu autoSelect={false}>
                    <MenuButton>
                      <Avatar
                        size={'md'}
                        name={user?.email}
                        src={user?.photoURL}
                      />
                    </MenuButton>

                    <MenuList sx={{ minWidth: '180px' }}>
                      {userInfo?.level === 1 ? (
                        <Flex
                          direction="column"
                          align="center"
                          width="100%">
                          <Link
                            href="/adminPage"
                            style={{ width: '100%' }}
                            _hover={{ textDecoration: 'none' }}>
                            <MenuItem
                              width={'100%'}
                              textAlign="center"
                              justifyContent="center">
                              관리자 페이지
                            </MenuItem>
                            <MenuDivider />
                          </Link>
                        </Flex>
                      ) : null}

                      <MenuItem
                        width={'100%'}
                        textAlign="center"
                        justifyContent="center"
                        onClick={onLogOutClick}>
                        Log out
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </Flex>
            </Flex>
          </Box>
        </>
      )}
    </Box>
  )
}
